/* eslint-disable @typescript-eslint/no-explicit-any */
export const generateQueryToUrl = (query: any, baseUrl: string) => {
  const queryString = Object.keys(query)
    .map((key) => key + '=' + query[key])
    .join('&');
  const url = decodeURI(`${baseUrl}${!queryString ? '' : '?' + queryString}`);
  return url;
};

export const generateUrlToQuery = (
  input: InputObject,
  omitList: string[]
): OutputObject => {
  const output: OutputObject = Object.entries(input).reduce(
    (acc, [key, value]) => {
      if (omitList.includes(key)) return acc;
      acc[key] = value.split(',');
      return acc;
    },
    {} as any
  );

  return output;
};

export type InputObject = {
  [key: string]: string;
};

export type OutputObject = {
  [key: string]: string[];
};
