import styled from 'styled-components';

export const NavItem = styled.a`
  text-decoration: none;
  font-weight: 700;
  color: #1e242f;
  padding: 6px 20px;
  border-radius: 4px;
`;

export const CollapseHolder = styled.div`
  position: absolute;
  height: auto;
  overflow: hidden;
  width: 100%;
  background-color: #00000066;
  min-height: 100vh;
  z-index: 998;
`;

export const CollapseInner = styled.div`
  position: relative;
  width: auto;
  padding: 15px 20px 30px 20px;
  background-color: #fff;
  z-index: 999;
`;

export const LogoWrapper = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  max-height: 32px;

  // width: 178.5px;
  // height: 32px;
  // margin-left: 50%;

  @media only screen and (max-width: 768px) {
    max-height: 24px;
  }

  // @media only screen and (max-width: 431px) {
  //   margin-left: 5%;
  //   height: 32px;
  //   width: 178.5px;
  // }
`;

export const DeskLogoInner = styled.div`
  display: block;
  height: 32px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MbLogoInner = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    max-height: 24px;
  }
`;

export const LanguageWrapper = styled.div`
  display: block;
  position: relative;

  &:hover {
    svg path {
      fill: #31acbf;
      stroke: #31acbf;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const LanguageIconWrapper = styled.div`
  display: none;
  height: 18px;

  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
  }
`;

export const StyledSearchBox = styled.form`
  width: -webkit-fill-available;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;

  ::placeholder {
    color: #a8b5c9;
  }
`;

export const StyledInput = styled.input`
  width: 100%;
  border: transparent;
  height: 100%;
  background-color: transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  color: #1e242f;
  outline: none;
  padding-right: 15px;

  ::placeholder {
    color: #a8b5c9;
  }
`;

export const StyledButton = styled.button`
  background-color: #31acbf;
  border: transparent;
  cursor: pointer;
  justify-content: center;
  padding: 10px 20px 10px 20px;
  text-align: center;
  white-space: nowrap;
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  color: #fff;
`;

export const IconOnly = styled.div`
  width: 24px;
  height: 24px;
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

export const SubMenu = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding-top: 8px;
  border-top: 1px solid #28282820;
  gap: 4.7rem;
  justify-content: flex-start;

  @media only screen and (max-width: 768px) {
    gap: 3rem;
  }

  @media only screen and (max-width: 316px) {
    flex-flow: column wrap;
    gap: 0;
  }
`;

export const MenuItem = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  padding: 7px 0;

  span {
    cursor: pointer;
  }
`;

export const SubMegaMenu = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 12px;
  margin-bottom: 12px;
`;

export const MegaMenuItem = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  padding-left: 3%;
  color: #1e242f;
  width: 100%;
  span {
    cursor: pointer;
  }
`;

export const MenuTitle = styled.h5`
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1e242f;
`;

export const MenuBurgerWrap = styled.div`
  user-select: none;
`;

export const StyledTopLeftMenuWrapper = styled.div`
  @media only screen and (max-width: 600px) {
    display: none;
  }
  @media only screen and (min-width: 601px) {
    display: flex;
  }
`;

export const CategoriesBoxWrapper = styled.div<{
  $show?: boolean;
}>`
  position: sticky;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 2000ms;
  will-change: top;
  top: 0;
  height: 100%;
  z-index: 10;
  background-color: #fff;
  @media only screen and (max-width: 768px) {
    top: ${(props) => (props.$show ? 0 : '-100px')};
    opacity: ${(props) => (props.$show ? 1 : 0)};
    display: ${(props) => (props.$show ? 'block' : 'none')};
  }
`;

export const SubCateBoxWrapper = styled.div<{
  $show?: boolean;
}>`
  position: sticky;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  will-change: top;
  top: 0px;
  height: 100%;

  @media only screen and (max-width: 768px) {
    top: ${(props) => (props.$show ? 0 : '-100px')};
    opacity: ${(props) => (props.$show ? 1 : 0)};
    display: ${(props) => (props.$show ? 'block' : 'none')};
  }
`;

export const LanguageTag = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 7px;
  font-weight: 400;
  padding: 1px 2px;
  background-color: #31acbf;
  color: #fff;
  margin-right: -6px;
  margin-top: -3px;
  border-radius: 2px;
`;

export const NavBarSection = styled.div`
  gap: 10px;
  @media only screen and (max-width: 768px) {
    gap: 7px;
  }
`;

export const LanguageIconBox = styled.div`
  display: none;
  padding: 12px 0;

  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
  }
`;

export const StyledMainWrapper = styled.div<{ $containerNoCss: boolean }>(
  (props) => {
    const noCss = props.$containerNoCss;
    if (noCss) return {};
    return {
      position: 'sticky',
      top: 0,
      zIndex: 1301,
      backgroundColor: '#FFFFFF',
    };
  }
);
