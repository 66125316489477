export type LanguageType = {
  label: string;
  value: string;
};

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const useLanguageItems = (): LanguageType[] => {
  return [
    {
      label: 'EN',
      value: 'en',
    },
    {
      label: 'BM',
      value: 'ms',
    },
    {
      label: '中文',
      value: 'zh',
    },
  ];
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default useLanguageItems;
