import { createContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

export const WebviewContext = createContext(null as unknown);
export const WebviewConsumer = WebviewContext.Consumer;

export type WebviewProviderProps = {
  children: React.ReactNode;
  context?: unknown;
};

export const WebviewProvider: React.FC<WebviewProviderProps> = (props) => {
  const { children } = props;
  const router = useRouter();
  const [isPostMessage, setIsPostMessage] = useState(false);

  useEffect(() => {
    const webview = router.pathname.startsWith('/webview') ?? false;
    if (webview) setIsPostMessage(true);
  }, [router]);

  return (
    <WebviewContext.Provider
      value={{
        isPostMessage,
      }}
    >
      {children}
    </WebviewContext.Provider>
  );
};
