/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import placeholderImage from '@stack/assets/images/empty_placeholder.jpg';
import { useAmp } from 'next/amp';
import Image from 'next/image';

import { ImageProps } from './props';

export const StyledImage: React.FC<ImageProps | any> = (props) => {
  // ======================= PROPS
  const {
    src,
    fallbackSrc,
    alt,
    height,
    width,
    imageStyle,
    onClick,
    isGallery,
    checkAdblock,
  } = props;
  const [imgSrc, set_imgSrc] = useState(src);
  const [hasAdBlock, setHasAdBlock] = useState(false);

  useEffect(() => {
    set_imgSrc(src);
  }, [src]);

  // ======================= Hooks
  const isAmp = useAmp();

  // ======================= VIEWS
  if (isAmp) {
    return (
      <amp-img
        on={isGallery ? 'tap:lightbox1' : ''}
        tabindex="0"
        role="button"
        width={`${width}px`}
        height={`${height}px`}
        src={imgSrc}
        alt={alt ?? ''}
        layout="responsive"
        style={{
          width: '100%',
          height: '100%',
          ...imageStyle,
        }}
      />
    );
  }
  if (hasAdBlock) return null;
  return (
    <Image
      onClick={onClick}
      loading="eager"
      priority
      width={width ?? 414}
      height={height ?? 700}
      src={imgSrc}
      alt={alt || ''}
      quality={55}
      style={{
        width: '100%',
        height: 'auto',
        objectFit: 'cover',
        ...imageStyle,
      }}
      onLoadingComplete={(result) => {
        if (result.naturalWidth === 0) {
          // Broken image
          if (checkAdblock) {
            setHasAdBlock(true);
          } else {
            set_imgSrc(fallbackSrc ?? placeholderImage.src);
          }
        }
      }}
      onError={() => {
        if (checkAdblock) {
          setHasAdBlock(true);
        } else {
          set_imgSrc(fallbackSrc ?? placeholderImage.src);
        }
      }}
      unoptimized
    />
  );
};

// ======================= EXPORT
export * from './props';
export default StyledImage;
