export const localeConversions = (locale: string) => {
  switch (locale) {
    case 'zh':
      return 'cn';
    case 'ms':
      return 'bm';
    case 'en':
    default:
      return 'en';
  }
};
