/* eslint-disable @typescript-eslint/no-explicit-any */
// src/ContextProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getAllEvOverview } from '@apps/config/API/new-car.config';
import Cookies from 'js-cookie';

interface PinnedDataContextProps {
  data: any[]; // Adjust the type according to your API response
  ids: string[];
  addId: (newId: string) => void;
  removeId: (id: string) => void;
}

const PinnedDataContext = createContext<PinnedDataContextProps | undefined>(
  undefined
);

export const PinnedProvider: React.FC<any> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [ids, setIds] = useState<string[]>(Cookies.getJSON('pinned') || []);

  useEffect(() => {
    fetchMultipleData(ids);
    Cookies.set('pinned', JSON.stringify(ids));
  }, [ids]);

  const fetchMultipleData = async (ids: string[]) => {
    try {
      const results = await getAllEvOverview(ids.join(','));
      setData(results?.data || []);
      Cookies.set('pinned', JSON.stringify(ids));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addId = (newId: string) => {
    setIds((prev) => {
      return [...prev, newId];
    });
  };

  const removeId = (id: string) => {
    setIds((prev) => {
      return prev.filter((existingId) => existingId !== id);
    });
  };

  return (
    <PinnedDataContext.Provider value={{ data, ids, addId, removeId }}>
      {children}
    </PinnedDataContext.Provider>
  );
};

export const usePinnedData = () => {
  const context = useContext(PinnedDataContext);
  if (!context) {
    throw new Error('usePinnedData must be used within a PinnedProvider');
  }
  return context;
};
