const siteUrl = process.env.NEXT_PUBLIC_BASE_URL || 'https://www.carz.com.my';

export const SEO = {
  title:
    'Malaysia Automotive News: Latest, Car Launches, Reviews & Insights | Carz Automedia Malaysia',
  titleTemplate:
    'Malaysia Automotive News: Latest, Car Launches, Reviews & Insights | Carz Automedia Malaysia',
  description:
    'Search for new cars, used cars & recon cars on Carz.com.my! - an up & coming online car buying site in Malaysia. Find your next car and stay up-to-date with the latest news, launches, car prices, and more!',
  openGraph: {
    type: 'website',
    url: siteUrl,
    siteName: 'Carz Automedia Malaysia',
    title:
      'Malaysia Automotive News: Latest, Car Launches, Reviews & Insights | Carz Automedia Malaysia',
    description:
      'Search for new cars, used cars & recon cars on Carz.com.my! - an up & coming online car buying site in Malaysia. Find your next car and stay up-to-date with the latest news, launches, car prices, and more!',
    images: [
      {
        url: `${siteUrl}/carz-automedia-logo.png`,
        alt: 'Carz Automedia',
        type: 'image/png',
      },
    ],
  },
};

export default SEO;
