import KeyboardArrowDownIcon from '@mui/icons-material/ExpandMoreOutlined';
import { alpha } from '@mui/material';
import { ThemeConfig } from '@webbyx/mui';
/**
 * ===========================
 * MAIN
 * ===========================
 */

export const useThemeConfig = (): Partial<ThemeConfig> => {
  return {
    settings: {
      mode: 'light',
      skin: 'bordered',
    },
    overrides: {
      palette: {
        primary: {
          main: '#FFC800',
        },
        secondary: {
          main: '#31ACBF',
        },
        text: {
          primary: '#1E242F',
          secondary: '#67708C',
        },
        background: {
          default: '#FFF',
        },
        grey: {
          100: '#F7F7F7',
          200: '#E9E9EC',
          300: '#EDEDEF',
          800: '#A8B5C9',
          900: '#4c4e64',
        },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 576,
          md: 768,
          lg: 1024,
          xl: 1440,
        },
      },
      typography: {
        fontFamily: ['Roboto', 'sans-serif'].join(','),
      },
      components: {
        // Menu Item
        MuiMenuItem: {
          styleOverrides: {
            root: ({ theme }) => {
              const background = alpha(theme.palette.secondary.main, 0.15);
              return {
                '.MuiTouchRipple-root': {
                  display: 'none',
                },
                backgroundColor: theme.palette.background.default,
                '&.Mui-selected': {
                  backgroundColor: background,
                  '&.Mui-focusVisible, &:hover': { background },
                },
              };
            },
          },
        },

        // Popover
        MuiPopover: {
          styleOverrides: {
            paper: ({ theme }) => {
              return {
                '::-webkit-scrollbar': {
                  width: '6px',
                },
                '::-webkit-scrollbar-thumb': {
                  backgroundColor: theme.palette.secondary.main,
                  borderRadius: '50px',
                },
                '::-webkit-scrollbar-thumb:hover': {
                  background: theme.palette.secondary.main,
                },
              };
            },
          },
        },

        MuiCheckbox: {
          styleOverrides: {
            root: ({ theme }) => {
              return {
                color: theme.palette.text.primary,
                '&.Mui-checked': {
                  color: theme.palette.secondary.main,
                },
                '&.MuiCheckbox-indeterminate': {
                  color: theme.palette.secondary.main,
                },
              };
            },
          },
        },

        // Select
        MuiSelect: {
          defaultProps: {
            IconComponent: KeyboardArrowDownIcon,
          },
          styleOverrides: {
            icon: ({ theme, ownerState: { disabled } }) => {
              return {
                ...(!disabled && { fill: theme.palette.text.primary }),
              };
            },
            root: ({ theme, ownerState: { disabled } }) => {
              return {
                backgroundColor: `${
                  disabled ? theme.palette.grey[300] : theme.palette.grey[100]
                }`,
                '&.Mui-disabled ~ .MuiOutlinedInput-notchedOutline': {
                  border: `0 !important`,
                },
                borderRadius: '2px',
                '&:hover fieldset, &.Mui-focused fieldset': {
                  border: `${
                    disabled ? '0' : `1px solid ${theme.palette.secondary.main}`
                  } !important`,
                },
              };
            },
          },
        },

        // Text Field
        MuiTextField: {
          styleOverrides: {
            root: ({ theme, ownerState: { disabled } }) => {
              const commonBorderStyles = {
                borderRadius: 0,
                border: '0px !important',
              };
              return {
                // If dont want to show the number spin when type is number, pass disableNumberSpin to props className
                '&.disableNumberSpin': {
                  'input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button':
                    {
                      MozAppearance: 'textfield',
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                },
                border: '0px !important',
                backgroundColor: theme.palette.grey[100],
                '& .MuiOutlinedInput-root': {
                  '& fieldset': commonBorderStyles,
                  '&:hover fieldset, &.Mui-focused fieldset': {
                    ...commonBorderStyles,
                    ...(!disabled && {
                      border: `1px solid ${theme.palette.secondary.main} !important`,
                    }),
                  },
                  '& input:-webkit-autofill, & input:-webkit-autofill:hover, & input:-webkit-autofill:focus, & input:-webkit-autofill:active':
                    {
                      '-webkit-box-shadow': `0 0 0 30px ${theme.palette.grey[100]} inset`,
                      borderRadius: '0 !important',
                    },
                  '& input::placeholder': {
                    opacity: 0.5,
                    color: theme.palette.grey[800],
                    fontSize: '0.875rem',
                  },
                  '&.Mui-disabled': {
                    backgroundColor: `${theme.palette.grey[300]}`,
                    ...commonBorderStyles,
                  },
                },
              };
            },
          },
        },

        // Button
        MuiButton: {
          variants: [
            {
              props: { variant: 'outlined' },
              style: {
                borderColor: '#EDECED',
                backgroundColor: 'white',
                color: '#31ACBF',
                '&:hover': {
                  color: '#31ACBF',
                  borderColor: '#31ACBF',
                  backgroundColor: `${alpha('#31ACBF', 0.08)} !important`,
                },
              },
            },

            {
              props: { variant: 'outlined', color: 'secondary' },
              style: {
                borderColor: '#EDECED',
                backgroundColor: 'white',
                color: '#31ACBF',
                '&:hover': {
                  color: '#31ACBF',
                  borderColor: '#EDECED',
                  backgroundColor: `#F7F7F7 !important`,
                },
              },
            },

            {
              props: { variant: 'contained', color: 'secondary' },
              style: {
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: `#2EA1B2 !important`,
                  boxShadow: 'none',
                },
              },
            },

            {
              props: {
                variant: 'text',
                color: 'secondary',
              },
              style: {
                padding: '0px',
                borderRadius: '4px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: `#F7F7F7 !important`,
                },
                minWidth: 'auto',
              },
            },

            {
              props: {
                variant: 'text',
                color: 'info',
              },
              style: {
                color: '#1E242F',
                padding: '0px',
                textTransform: 'none',
                '&:hover': {
                  backgroundColor: `#F7F7F7 !important`,
                },
                minWidth: 'auto',
              },
            },
          ],
          defaultProps: { disableTouchRipple: true },
          styleOverrides: {
            root: ({ theme }) => {
              return {
                '&:hover': {
                  backgroundColor: theme.palette.secondary.main,
                  opacity: 0.9,
                },
              };
            },
          },
        },

        // Chip
        MuiChip: {
          defaultProps: {},
          variants: [
            // ================== Default
            {
              props: { variant: 'outlined', color: 'default' },
              style: {
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(237, 236, 237, 0.6) !important',
                },
              },
            },

            // ================== Primary
            {
              props: { variant: 'outlined', color: 'primary' },
              style: {
                backgroundColor: 'transparent',
                '&:hover': {
                  backgroundColor: 'rgba(255, 200, 0, 0.15) !important',
                },
              },
            },
            {
              props: { variant: 'filled', color: 'primary' },
              style: {
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(255, 200, 0, 0.85) !important',
                },
              },
            },

            // ================== Secondary
            {
              props: {
                variant: 'outlined',
                color: 'secondary',
              },
              style: {
                border: '1px solid #31ACBF',
                backgroundColor: 'rgba(49, 172, 191, 0.15)',
                '&:hover': {
                  backgroundColor: 'rgba(49, 172, 191, 0.25) !important',
                },
              },
            },
            {
              props: { variant: 'filled', color: 'secondary' },
              style: {
                color: 'white',
                '&:hover': {
                  backgroundColor: 'rgba(49, 172, 191, 0.85) !important',
                },
              },
            },
          ],
          styleOverrides: {
            root: ({ theme }) => {
              return {
                color: theme.palette.text.primary,
                '&:active': {
                  boxShadow: 'none',
                },
              };
            },
          },
        },
        MuiUseMediaQuery: {
          defaultProps: {
            noSsr: false,
          },
        },
      },
    },
    enableLayoutLoader: false,
    layoutLoaderLasting: 2000,
  };
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default useThemeConfig;
