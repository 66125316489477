/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from 'react';
import { useRouter } from 'next/router';

import { WebviewContext } from './context';

type ClickEventOptionProps = {
  title?: string;
  lang?: string;
  params?: any;
};

export const usePostMsgContext = () => {
  const context = useContext(WebviewContext);
  const { isPostMessage } = context as any;
  const router = useRouter();

  return {
    isPostMessage,
    clickEvent: (
      url: string,
      back?: boolean,
      options: ClickEventOptionProps = {}
    ) => {
      if (!isPostMessage) return undefined;
      return (e: { preventDefault: () => void }) => {
        const { title, params } = options;
        const x = JSON.stringify({
          url,
          lang: router?.locale ?? 'en',
          ...(back ? { back: true } : { back: false }),
          ...(title && { title }),
          ...(params && { params }),
        });
        e.preventDefault();
        //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
        if (typeof window !== 'undefined' && window.ReactNativeWebView) {
          //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
          window.ReactNativeWebView.postMessage(x);
        }
      };
    },
    clickAction: (
      url: string,
      back?: boolean,
      options: ClickEventOptionProps = {}
    ) => {
      if (!isPostMessage) return undefined;
      const { title, params, lang } = options;
      const x = JSON.stringify({
        url,
        lang: lang || router?.locale || 'en',
        ...(back ? { back: true } : { back: false }),
        ...(title && { title }),
        ...(params && { params }),
      });
      //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
      if (typeof window !== 'undefined' && window.ReactNativeWebView) {
        //@ts-expect-error Property 'ReactNativeWebView' does not exist on type 'Window & typeof globalThis'.
        window.ReactNativeWebView.postMessage(x);
      }
    },
  };
};

export default usePostMsgContext;
