/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'isomorphic-unfetch';

import { BASE_ENDPOINT } from '../constant';

export const getSettings = async (): Promise<any> => {
  const url = `${BASE_ENDPOINT}/setting`;
  //  const url = `${BASE_ENDPOINT}/setting_media`;

  try {
    if (!url) return {};
    const res = await fetch(url, {
      method: 'GET',
    });

    return res.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export default getSettings;
