/* eslint-disable @typescript-eslint/no-explicit-any */
// src/ContextProvider.tsx
import React, { createContext, useContext, useEffect, useState } from 'react';
import { getCompareResult } from '@apps/config/API/compare-settings';
import Cookies from 'js-cookie';

interface DataContextProps {
  data: any[]; // Adjust the type according to your API response
  ids: string[];
  addId: (newId: string) => void;
  removeId: (id: string) => void;
}

const DataContext = createContext<DataContextProps | undefined>(undefined);

export const DataProvider: React.FC<any> = ({ children }) => {
  const [data, setData] = useState<any[]>([]);
  const [ids, setIds] = useState<string[]>(Cookies.getJSON('ids') || []);

  useEffect(() => {
    if (ids.length > 0) {
      fetchMultipleData(ids);
    }
  }, [ids]);

  const fetchMultipleData = async (ids: string[]) => {
    try {
      const results = await getCompareResult(ids.join(','));
      setData(results?.data);
      Cookies.set('ids', ids);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const addId = (newId: string) => {
    if (ids.includes(newId)) {
      return;
    }
    const updatedIds = [...ids, newId];
    setIds(updatedIds);
    fetchMultipleData(updatedIds);
    Cookies.set('ids', updatedIds);
  };

  const removeId = (id: string) => {
    const index = ids.indexOf(id.toString());
    if (index > -1) {
      const updatedIds = [...ids];
      updatedIds.splice(index, 1);
      setIds(updatedIds);
      fetchMultipleData(updatedIds);
      Cookies.set('ids', updatedIds);
    }
  };

  return (
    <DataContext.Provider value={{ data, ids, addId, removeId }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
