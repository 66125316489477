/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
import App from 'next/app';
import { NextComponentType, NextPageContext } from 'next/types';

import getSettings from '../API/setting.config';

// ======================= HOC
export const withSettingsConfig =
  (): any =>
  <P extends any>(
    PageComponent: NextComponentType<NextPageContext, any, P>
  ) => {
    const WithSettings = (props: any) => {
      const { pageProps, ...restProps } = props;
      return <PageComponent {...restProps} pageProps={pageProps} />;
    };

    if (process.env.NODE_ENV !== 'production') {
      const displayName =
        PageComponent.displayName || PageComponent.name || 'Component';
      WithSettings.displayName = `WithSettings(${displayName})`;
    }

    WithSettings.getInitialProps = async (ctx: any) => {
      const inAppContext = Boolean(ctx.ctx);
      const result = await getSettings();

      let props: any = {};
      if (PageComponent.getInitialProps) {
        props = await PageComponent.getInitialProps(ctx);
      } else if (inAppContext) {
        props = await App.getInitialProps(ctx);
      }

      return {
        ...props,
        pageProps: {
          ...(props?.pageProps ?? {}),
          settings: result ?? {},
        },
      };
    };
    return WithSettings;
  };

export default withSettingsConfig;
