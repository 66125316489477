import styled from 'styled-components';

export const FooterItem = styled.a`
  text-decoration: none;
  font-weight: 500;
  color: #1e242f;
`;
export const FooterWrapper = styled.div`
  border-top: 2px solid #1e242f;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const IconsAndImageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`;

export const CopyrightInfoWrapper = styled.div`
  gap: 10px;
  display: flex;

  @media only screen and (min-width: 768px) {
    align-self: flex-end;
  }
`;

export const CopyrightIconWrapper = styled.div`
  width: 50px;
  height: 50px;

  @media only screen and (max-width: 768px) {
    width: 32px;
    height: 32px;
  }
`;

export const FooterItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 30px;

  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    align-items: flex-start;
  }
`;

export const FooterSectionWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 20px;
  width: 100%;

  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const AboutUsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-left: 5px solid #1e242f;
  padding-left: 25px;
  gap: 20px;

  @media only screen and (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 10px;
  }
`;

export const IconButton = styled.div`
  background-color: #1e242f;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 36px;
  width: 36px;

  :hover {
    background-color: #31acbf;
  }

  @media only screen and (max-width: 768px) {
    height: 42px;
    width: 42px;

    svg {
      height: 22px;
      width: 22px;
    }
  }
`;
