/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'isomorphic-unfetch';

import { BASE_ENDPOINT } from '../constant';

export const getCompareSettings = async (): Promise<any> => {
  const url = `${BASE_ENDPOINT}/comparison_setting`;

  try {
    if (!url) return {};
    const res = await fetch(url, {
      method: 'GET',
    });

    return res?.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export const getCompareResult = async (data: any): Promise<any> => {
  const url = `${BASE_ENDPOINT}/new_car_comparison?ids=${data}`;
  try {
    if (!url) return {};
    const res = await fetch(url, {
      method: 'GET',
    });

    return res?.json();
  } catch (error) {
    console.error('Fetch Error:', error);
    return {};
  }
};

export default getCompareSettings;
