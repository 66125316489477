import classNames from 'classnames';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const AnimatedBurger: React.FC<any> = (props) => {
  // ======================= HOOKS
  const { onClick, toggle } = props;

  // ======================= VIEWS
  return (
    <button
      className={classNames('nav-toggle', {
        opened: toggle,
      })}
      onClick={onClick}
    >
      <span className="bar-top"></span>
      <span className="bar-mid"></span>
      <span className="bar-bot"></span>
    </button>
  );
};

// ======================= EXPORT
export default AnimatedBurger;
