type CreateBasePathProps = {
  webView?: boolean;
  type: 'news' | 'cars';
};

const createBasePath = (props: CreateBasePathProps) => {
  const { webView, type } = props;

  let basePath = '';

  switch (type) {
    case 'cars':
      basePath = webView ? '/webview/cars' : '/cars';
      break;
    default:
    case 'news':
      basePath = webView ? '/webview' : '';
      break;
  }

  return { basePath };
};

export default createBasePath;
