import React, { ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';

interface Option {
  value: string;
  label: string;
}

interface SelectDropdownProps {
  options: Option[];
  value: string;
  onChange: (value: string) => void;
  displayIcon?: boolean;
  icon?: ReactNode;
}

const SelectDropdown: React.FC<SelectDropdownProps> = ({
  options,
  value,
  onChange,
  displayIcon = false,
  icon,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState(value);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option.label);
    onChange(option.value);
    setIsActive(false);
  };

  useEffect(() => {
    setSelectedOption(value);
  }, []);

  useEffect(() => {
    const sOpt = options.find((opt) => {
      return opt.value === value;
    });
    setSelectedOption(sOpt?.label ?? value);
  }, [value]);

  return (
    <div
      className={classNames('text-sm', {
        'custom-select': !displayIcon,
        'custom-icon-select': displayIcon,
        active: isActive,
      })}
    >
      <button
        className={classNames('', {
          'select-button': !displayIcon,
          'icon-button': displayIcon,
        })}
      >
        {!displayIcon ? (
          <>
            <span className="selected-value text-sm">
              {selectedOption || 'Select'}
            </span>
            <span className="arrow"></span>
          </>
        ) : (
          icon
        )}
      </button>

      <ul
        className={classNames('select-dropdown', {
          'right-0': displayIcon,
        })}
        role="listbox"
        id="select-dropdown"
      >
        {options.map((option, index) => (
          <li onClick={(e) => handleOptionClick(option)} key={option.value}>
            <input type="radio" id={`${index}`} value={option.value} />
            <label className="text-xs">{option.label}</label>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SelectDropdown;
