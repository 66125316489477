/* eslint-disable @typescript-eslint/no-explicit-any */
import SearchIcon from '@stack/assets/icons/search-primary.svg';
import styled from 'styled-components';

const IconOnly = styled.div`
  width: 24px;
  height: 24px;
  display: none;
  transition: all 1s east-in-out;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const InputWrapper = styled.div`
  display: flex;
  background-color: #f7f7f7;
  border-radius: 2px;
  align-items: center;
  padding: 4px 8px;
  gap: 8px;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const StyledInput = styled.input`
  border: none;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #1e242f;
  outline: none;

  ::placeholder {
    color: #a8b5c9;
  }
`;

/**
 * ===========================
 * MAIN
 * ===========================
 */
export const SearchInput: React.FC<any> = (props) => {
  // ======================= HOOKS
  const {
    onHandleCollapse,
    collapseState,
    onChange,
    onHandleSubmit,
    value,
    showClose = false,
  } = props;
  // ======================= STATE

  // ======================= API

  // ======================= EVENTS

  // ======================= VARIABLES

  // ======================= RENDER FUNCTIONS

  // const renderIcon = () => {
  //   if (collapseState)
  //     return <span className="material-symbols-outlined">close</span>;
  //   return <StyledImage src={SearchIcon.src} alt="" width={1} height={1} />;
  // };

  // ======================= VIEWS
  return (
    <>
      <IconOnly
        style={{
          width: 24,
          height: 24,
        }}
        onClick={() => onHandleCollapse(!collapseState, 'search')}
      >
        {!showClose ? <SearchIcon /> : <></>}
      </IconOnly>
      <InputWrapper>
        <div
          style={{
            width: 24,
            height: 24,
          }}
        >
          <SearchIcon />
        </div>
        <form onSubmit={onHandleSubmit} action="/" target="_top">
          <StyledInput
            type="text"
            placeholder="" // BYD Dolphin
            style={{
              border: 'none',
              backgroundColor: 'transparent',
              fontSize: 12,
              fontWeight: 500,
              color: '#1E242F',
              outline: 'none',
            }}
            onChange={onChange}
            value={value}
          />
        </form>
      </InputWrapper>
    </>
  );
};

/**
 * ===========================
 * EXPORTS
 * ===========================
 */
export default SearchInput;
