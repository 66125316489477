/**
 * ===========================
 * CONSTANT
 * ===========================
 */
export const APP_NAME = 'Carz Automedia Malaysia';

/**
 * ===========================
 * ENVIRONMENT VARIABLES
 * ===========================
 */
// website url
export const BASE_URL =
  process.env.NEXT_PUBLIC_BASE_URL || 'http://localhost:4444';

// website url
export const PARENT_URL =
  process.env.NEXT_PUBLIC_PARENT_URL || 'https://www.caricarz.com';
// api endpoint (base url)
export const BASE_ENDPOINT =
  process.env.NEXT_PUBLIC_API_URL || 'http://dev-api.caricarz.com';

// api endpoint (graphql endpoint)
export const API_ENDPOINT =
  process.env.NEXT_PUBLIC_API_ENDPOINT || 'https://dev-api.caricarz.com';

// api endpoint (graphql subscription endpoint)
export const GRAPHQL_SUBSCRIPTION_ENDPOINT =
  process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT || 'ws://localhost:3333/graphql';

// for social shares
export const SHARE_LINK = BASE_URL;

// Google Ads sense
export const GOOGLE_ADS_CLIENT_ID = process.env.NEXT_GOOGLE_ADS_CLIENT_ID || '';

export const ENABLE_GOOGLE_ADS =
  process.env.NEXT_PUBLIC_ENABLE_GOOGLE_ADS === 'true' || false;

export const GOOGLE_ANALYTICS_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS || '';

export const GOOGLE_TAG_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_KEY || '';

export const FB_PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL || '';

// footer links
export const FB_PAGE_URL = 'https://www.facebook.com/caricarz.my';
export const YT_PAGE_URL =
  'https://www.youtube.com/channel/UC5BqQOXc1NbPKmM0zFJsZjA';
export const TWITTER_PAGE_URL = 'https://twitter.com/caricarz/';

/**
 * ===========================
 * APP ROUTE
 * ===========================
 */
// default path after login
export const DEFAULT_PATH_AFTER_SIGN_IN = '/';

// default path before login
export const DEFAULT_PATH_BEFORE_SIGN_IN = '/';

// path for login page
export const SIGN_IN_PATH = '/signin';

// page not found page
export const NOT_FOUND_PATH = '/404';

// onboarding path
export const ONBOARDING_PATH = '/onboarding';

// path accessible by public
// by default, this works as a whitelist.
// everything else that is not listed here will not be accessible by the public
export const CAR_MODULE_PATHS = [
  '/webview/cars',
  '/webview/cars/[slug]',
  '/webview/cars/[slug]/[model]',
  '/webview/cars/ev-overview',
  '/webview/cars/new-launch',
  '/webview/cars/ev-test',
  '/webview/cars/ranking',
  '/webview/cars/calculator',
  '/webview/cars/comparison',
];

export const WEBVIEW_NO_HEADER = [
  '/webview/cars/[slug]',
  '/webview/cars/[slug]/[model]',
  '/webview/cars/ev-overview',
  '/webview/cars/new-launch',
  '/webview/cars/ev-test',
  '/webview/cars/ranking',
  '/webview/cars/calculator',
  '/webview/cars/calculator',
  '/webview/privacy-policy',
  '/webview/terms-of-use',
  '/webview/cars/comparison',
];

export const NEWS_MODULE_PATHS = [
  '/latest',
  '/latest/[brand]',
  '/latest/[brand]/[model]',
  '/[slug]',
  '/authors',
  '/author/[author]',
  '/search/[keyword]',
  '/tag/[keyword]',
  '/[category]',
  '/[category]/[...slug]',
  '/[category]/[[...slug]]',
  '/social-video',
  '/car-plate',
  '/privacy-policy',
  '/terms-of-use',
  '/browse-by-year',
  '/browse-by-year/[year]',
  '/browse-by-year/tag/[tag]',
  '/webview',
  '/webview/latest',
  '/webview/latest/[brand]',
  '/webview/latest/[brand]/[model]',
  '/webview/[slug]',
  '/webview/authors',
  '/webview/author/[author]',
  '/webview/search/[keyword]',
  '/webview/tag/[keyword]',
  '/webview/[category]',
  '/webview/[category]/[...slug]',
  '/webview/[category]/[[...slug]]',
  '/webview/social-video',
  '/webview/car-plate',
  '/webview/privacy-policy',
  '/webview/terms-of-use',
];

export const COMMON_PATHS = [
  '/404',
  '/500',
  '/test',
  '/webview/404',
  '/webview/500',
  '/webview/test',
  '/sitemap.xml',
  '/sitemap-0.xml',
  '/sitemap-news-listing.xml',
  '/robot.txt',
];

export const PATHS_ONLY_ALLOWED_BEFORE_AUTH = [
  SIGN_IN_PATH,
  ...COMMON_PATHS,
  ...NEWS_MODULE_PATHS,
  ...CAR_MODULE_PATHS,
];

// restricted path that can't be accessed if user has already signed in
export const PATHS_NOT_ALLOWED_AFTER_AUTH = [SIGN_IN_PATH];

// path for admin portal (optional)
export const PATHS_FOR_ADMIN_ONLY = ['/app'];

// config to defined path that only shows content, which has no header, footer, sidebar, etc
// NOTE: This needed to handle on frontend level
export const NO_LAYOUT_PATH = [SIGN_IN_PATH];
