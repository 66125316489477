// ComparisonMenu.js
import React from 'react';
import StyledImage from '@apps/components/Image';
import { useData } from '@apps/context';
import WarningIcon from '@mui/icons-material/Warning';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  StackProps,
  SvgIcon,
  Typography,
} from '@mui/material';
import { IconButton } from '@mui/material';
import CarIcon from '@stack/assets/images/comparison-car.svg';
import createBasePath from '@stack/helpers/lib/createBasePath';
import { useRouter } from 'next/router';

import usePostMsgContext from '../WebviewProvider/hooks';

export type ComparisonModalProps = StackProps & {
  disablePathChecing?: boolean;
};

const ComparisonModal: React.FC<ComparisonModalProps> = (props) => {
  const { disablePathChecing = false, ...containerProps } = props;
  const { sx: containerSx, ...restContainerProps } = containerProps;
  const router = useRouter();

  const { asPath, pathname } = router;
  const { data, removeId } = useData();
  const formatter = new Intl.NumberFormat('en-US');
  const webView = asPath?.includes('webview');
  const { clickAction } = usePostMsgContext();
  const { basePath } = createBasePath({ webView, type: 'cars' });
  const href = `${basePath}/comparison`;
  const disablePathList = [
    '/webview/cars/[slug]/[model]',
    '/cars/[slug]/[model]',
    '/webview/cars/comparison',
    '/cars/comparison',
  ];
  const isNewCarDetail = disablePathList.includes(pathname);
  const disablePaths = isNewCarDetail && !disablePathChecing;
  const onClick = (event: { preventDefault: () => void }) => {
    if (!webView) {
      router.push(href);
      return;
    }
    event.preventDefault();
    clickAction(href, false, {
      title: 'Compare Car',
      params: {
        newPage: true,
      },
    });
  };

  const onRemove = (id: string) => {
    removeId(id);
  };

  const CarImageCard = (item: {
    new_car_slug: string;
    new_car_title: string;
    car_model_image: string;
    car_brand_name?: string;
    new_car_otr_price: string;
    hasMobileView?: boolean;
    car_variant_id: string;
    car_brand_slug: string;
  }) => {
    const {
      car_brand_name,
      new_car_slug,
      car_model_image,
      car_brand_slug,
      car_variant_id,
      new_car_title,
      new_car_otr_price,
    } = item;

    const newCarHref = `${basePath}/${car_brand_name}/${new_car_slug}`;

    const onClickCar = (event: { preventDefault: () => void }) => {
      if (!webView) return;
      event.preventDefault();
      clickAction(newCarHref, false, {
        title: new_car_title,
        params: {
          brand: car_brand_slug,
          slug: new_car_slug,
        },
      });
    };

    return (
      <Stack
        width={'100%'}
        minWidth={'100%'}
        alignItems={'center'}
        direction={'row'}
        maxHeight={54}
        justifyContent={'space-between'}
        gap={2.5}
      >
        <a
          rel="nofollow"
          href={newCarHref}
          target="_blank"
          style={{
            textDecoration: 'none',
            color: 'inherit',
            alignItems: 'center',
            padding: '0px !important',
          }}
          onClick={onClickCar}
          draggable={false}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: 2.5,
            }}
          >
            <Box
              sx={{
                minWidth: 96,
                width: 96,
                height: '100%',
                maxHeight: 54,
              }}
            >
              <StyledImage
                src={car_model_image}
                alt={new_car_slug}
                priority={true}
                height={54}
                width={96}
              />
            </Box>
            <Stack gap={{ xs: 0, lg: 1 }} width={'70%'}>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 700,
                  width: '100%',
                  display: '-webkit-box',
                  '-webkit-line-clamp': '1',
                  '-webkit-box-orient': 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  minHeight: '1.5rem', // Adjust based on your line height
                  color: 'text.primary',
                  ':hover': {
                    color: '#31acbf',
                  },
                }}
              >
                {new_car_title}
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  fontWeight: 500,
                }}
              >
                {+new_car_otr_price > 0
                  ? `RM ${formatter.format(+new_car_otr_price)}`
                  : 'TBC'}
              </Typography>
            </Stack>
          </Box>
        </a>
        <IconButton
          onClick={() => onRemove(car_variant_id)}
          sx={{
            padding: 0,
            backgroundColor: 'transparent !important',
          }}
        >
          <SvgIcon
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 21C6.45 21 5.97933 20.8043 5.588 20.413C5.19667 20.0217 5.00067 19.5507 5 19V6H4V4H9V3H15V4H20V6H19V19C19 19.55 18.8043 20.021 18.413 20.413C18.0217 20.805 17.5507 21.0007 17 21H7ZM17 6H7V19H17V6ZM9 17H11V8H9V17ZM13 17H15V8H13V17Z"
              fill="#67708C"
            />
          </SvgIcon>
        </IconButton>
      </Stack>
    );
  };

  if (
    asPath === '/cars/comparison' ||
    !data ||
    data?.length === 0 ||
    !asPath.includes('/cars') ||
    disablePaths
  )
    return null;

  return (
    <Stack
      sx={{
        position: 'fixed',
        bottom: 0,
        right: {
          xs: 0,
          lg: '10%',
        },
        justifyContent: 'space-between',
        alignItems: 'center',
        zIndex: 1300,
        width: {
          xs: '100%',
          md: '350px',
        },
        ...containerSx,
      }}
      {...restContainerProps}
    >
      <Accordion
        square
        disableGutters
        sx={{
          ':last-of-type': {
            borderRadius: 0,
          },
          border: 'none !important',
          borderBottom: { xs: 'none', md: '1px solid #EDECED !important' },
          bgcolor: 'transparent',
          width: '100%',
        }}
      >
        <AccordionSummary
          sx={{
            fontSize: 16,
            fontWeight: 700,
            border: 'none',
            width: '100%',
            py: 2.5,
            px: 5,
            borderTopLeftRadius: { xs: 0, md: 4 },
            borderTopRightRadius: { xs: 0, md: 4 },
            bgcolor: '#31ACBF',
            '& .MuiAccordionSummary-content': {
              margin: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            },
          }}
        >
          <Stack direction="row" width="100%" alignItems="center">
            <Stack direction="row" gap={2.5} alignItems="center" flexGrow={1}>
              <Stack width={24} height={24} position={'relative'}>
                <CarIcon />
              </Stack>
              <Typography color="white">Comparison</Typography>
            </Stack>
            <Stack
              bgcolor="#FB5858"
              width={20}
              height={20}
              borderRadius={9999}
              justifyContent="center"
              alignItems="center"
            >
              <Typography color="white" fontSize={12}>
                {data?.length || 0}
              </Typography>
            </Stack>
          </Stack>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            fontSize: 14,
            p: 0,
            bgcolor: 'white',
            border: '1px solid #EDECED',
            borderTop: 0,
            borderBottom: { xs: 'none', lg: '1px solid #EDECED' },
            borderLeft: { xs: 'none', md: '1px solid #EDECED' },
            borderRight: { xs: 'none', md: '1px solid #EDECED' },
          }}
        >
          <Stack>
            <Stack p={5} gap={4}>
              {data?.length === 4 && (
                <Stack gap={2.5} flexDirection="row" alignItems="center">
                  <WarningIcon
                    color={'primary'}
                    sx={{
                      width: '1.25rem',
                      height: '1.25rem',
                    }}
                  />
                  <Typography
                    fontSize={'.875rem'}
                    fontWeight={700}
                    color={'text.secondary'}
                  >
                    You can only compare up to 4 cars at once.
                  </Typography>
                </Stack>
              )}
              {data?.map((item, index) => (
                <Box key={`${item.id}-${index}`}>
                  <CarImageCard {...item} />
                </Box>
              ))}
            </Stack>
            <Stack borderTop={'1px solid #EDECED'} mt="auto">
              <Button
                onClick={onClick}
                fullWidth
                variant={'text'}
                color={'secondary'}
                sx={{
                  borderRadius: '0',
                  padding: '10px 20px',
                  textTransform: 'none',
                }}
              >
                Compare {data?.length} Car
              </Button>
            </Stack>
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  );
};

export default ComparisonModal;
